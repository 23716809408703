import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../assets/svg/BackButtonWhite.svg";
import BackIconblack from "../../assets/svg/BackButtonBlack.svg";
import PhotoCardDetail from "../../components/photoCardDetails";
import { getSinglePlaceAPI } from "../../api/api";
import { useEffect, useState } from "react";
import { PlaceInterface } from "../../models/placeModel";
import useOnMobile from "../../hook/useOnMobile";
import { twMerge } from "tailwind-merge";
import PrimaryButton from "../../components/primaryButton";
const CardDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [place, setPlace] = useState<PlaceInterface>();
  const { id } = useParams();
  const isMobile = useOnMobile();

  useEffect(() => {
    getPlaces();
  }, []);

  const getPlaces = () => {
    setLoading(true);
    getSinglePlaceAPI
      .getSinglePlace(id!)
      .then((res) => {
        setPlace(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center p-10 mt-5 w-full h-full">
          <span className="loader"></span>
        </div>
      ) : (
        <div
          className={twMerge(
            "flex  gap-12 pl-1 lg:mt-20 mt-2 mb-20 justify-center items-center w-full h-min",
            isMobile ? "flex-col" : "flex-row"
          )}
        >
          {!isMobile && (
            <div className={twMerge("flex justify-start items-start h-screen")}>
              <img
                src={isMobile ? BackIconblack : BackIcon}
                alt=""
                className="w-12 h-12 "
                onClick={() => navigate("/")}
              />
            </div>
          )}

          <div
            className={twMerge(
              "flex flex-col gap-8",
              isMobile ? "w-11/12" : ""
            )}
          >
            {place && (
              <PhotoCardDetail
                tags={place?.tags.map((tag: any) => tag.name)}
                photo={place?.image}
                title={place?.title}
                position={place?.location}
                description={place?.description}
                key={place?._id}
                cardId={place?._id}
              />
            )}
          </div>
          {isMobile && (
            <div className="w-full flex justify-center">
              <PrimaryButton
                buttonText="Back"
                onClick={() => navigate("/")}
                type="button"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default CardDetails;
