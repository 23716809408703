import PhotoCard from "../../components/photoCard";
import AddPhotoCard from "../../components/addPhotoCard";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/primaryButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import DownloadModal from "../../components/downloadModal";
import {
  deletePlaceAPI,
  getImage64API,
  getPlacesAPI,
  getSinglePlaceAPI,
  getTagsAPI,
} from "../../api/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Toast from "../../components/toast";
import SearchBar from "../../components/searchBar";
import FilterBar from "../../components/filterBar";
import Tag from "../../components/Tag";
import ConfirmModal from "../../components/confirmModal";
import DeleteIcon from "../../assets/svg/deleteIcon.svg";
import useOnMobile from "../../hook/useOnMobile";
import MobileAddPhotoCard from "../../components/MobileAddPhotoCard";
import LogOutIcon from "../../assets/svg/LogOut.svg";
import SecondaryButton from "../../components/secondaryButton";
import { logout } from "../../redux/reducers/userReducer";

const Home = () => {
  const navigate = useNavigate();
  const [toastStatus, setToastStatus] = useState<string>("none");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [singlePlace, setSinglePlace] = useState<any>();
  const [isDeleteModalOpen, SetisDeleteModalOpen] = useState<boolean>(false);
  const [isDownloadingImage, setIsDownloadingImage] = useState<boolean>(false);
  // Add state variables for tags and search
  const [tags, setTags] = useState<string>();
  const [search, setSearch] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(0);
  const [idOfCard, setIdOfCard] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const primaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.primaryColor
  );

  const queryClient = useQueryClient();
  const isMobile = useOnMobile();
  const dispatch = useDispatch();

  const { data: listOfTags } = useQuery(
    ["tags"],
    () => getTagsAPI.getTags().then((res) => res.data.tags),
    { keepPreviousData: true }
  );

  const secondaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.secondaryColor
  );
  const cardClicked = (id: string) => {
    navigate(`/card-details/${id}`);
  };

  const isAdmin = useSelector(
    (rootState: RootState) => rootState.userReducer.isAdmin
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const size: number = 10;
  const getCardDetailsAsAdmin = (cardId: string) => {
    openModal();
    getSinglePlaceAPI
      .getSinglePlace(cardId)
      .then((res) => {
        setSinglePlace(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setSinglePlace(null);
  };
  const handleSelectionOfTags = (tags: string[]) => {
    setSelectedTags(tags);
  };
  const tagClicked = (tag: string) => {
    let newTags;
    if (selectedTags.includes(tag)) {
      newTags = selectedTags.filter((selectedTag) => selectedTag !== tag);
    } else {
      newTags = [...selectedTags, tag];
    }
    handleSelectionOfTags(newTags);
    setTags(newTags.toString());
  };
  const LogOut = () => {
    localStorage.clear();
    dispatch(logout);
    navigate("/login");
    // window.location.href = "/welcome";
  };

  // makes a request to the backend for the base 64 image for download
  const downloadPhoto = async (url: string, name: string) => {
    setIsDownloadingImage(true);
    getImage64API
      .getImage64({ url: url })
      .then((res) => {
        const link = document.createElement("a");
        link.href =
          `data:${res.data.data.type};base64,` + res.data.data.base64_image;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        setIsDownloadingImage(false);
        setShowModal(false);
        handlePlaceAdditionResult(
          "success",
          "Il contenuto è stato scaricato con successo!!"
        );
      })
      .catch((err) => {
        console.log(err);
        setIsDownloadingImage(false);
        setShowModal(false);
        handlePlaceAdditionResult(
          "error",
          "Ops! Qualcosa è andato storto, riprova più tardi."
        );
      });
  };

  const {
    data: listOfPlaces,
    isLoading,
    error,
    isFetching,
  } = useQuery(
    ["places", { page, size, search, tags }],
    () =>
      getPlacesAPI.getPlaces({ size, page, search, tags }).then((res) => {
        setTotalPages(res.data.total_pages);
        return res.data.places.places;
      }),
    { keepPreviousData: true }
  );

  const deleteCard = (cardId: string) => {
    SetisDeleteModalOpen(true);
    setIdOfCard(cardId);
  };

  const handlePlaceAdditionResult = (color: string, message: string) => {
    setToastMessage(message);
    setToastStatus(color);
    // Reset toast status and message after 5 seconds
    setTimeout(() => {
      setToastStatus("none");
      setToastMessage("");
    }, 5000);
  };

  const { mutateAsync: deletePlaceMutation } = useMutation(
    (cardId: string) => deletePlaceAPI.deletePlace(cardId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("places");
        handlePlaceAdditionResult(
          "success",
          "hai eliminato con successo il luogo!"
        );
        SetisDeleteModalOpen(false);
        setIdOfCard("");
      },
      onError: () => {
        handlePlaceAdditionResult(
          "error",
          "Ops! Qualcosa è andato storto, riprova più tardi."
        );
      },
    }
  );

  if (error)
    return (
      <div className="flex items-center justify-center p-10 mt-5 md:h-screen h-screen w-full">
        <span className="flex text-RedPantone">Error Fetching Data</span>
      </div>
    );

  return (
    <div className="relative flex flex-col w-full">
      {isAdmin && (
        <div
          className="flex sm:flex-row flex-col px-10 py-6 items-start gap-5 justify-between"
          style={{ background: secondaryColor }}
        >
          <div className="flex sm:flex-row flex-col gap-4">
            <SearchBar onSearch={setSearch} />
            <FilterBar
              // this onclick isn for the filter button that is not applicable now
              onClick={() => {}}
              listOfTags={listOfTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              tagClicked={tagClicked}
            />
          </div>
          <div className="flex items-center justify-center">
            <SecondaryButton
              title={"Log Out"}
              icon={LogOutIcon}
              type={"button"}
              onClick={LogOut}
            />
          </div>
        </div>
      )}
      {/* warning, if you add is fecthing here, it will prevent you from opening the modal to create places */}
      {isLoading ? (
        <div className="flex items-center justify-center p-10 mt-5 h-screen w-full">
          <span className="loader"></span>
        </div>
      ) : (
        <div
          className="flex flex-col text-white md:h-full sm:h-screen sm:p-10  mt-5 w-full"
          style={{ background: isAdmin ? primaryColor : "" }}
        >
          {toastStatus === "success" && (
            <Toast color={toastStatus} message={toastMessage} />
          )}
          {toastStatus === "error" && (
            <Toast color={toastStatus} message={toastMessage} />
          )}

          {isAdmin && (
            <div className="flex items-center justify-start space-x-2 p-r-4 pb-4 pt-0">
              {selectedTags &&
                selectedTags.map((oneTag: string, index: number) => (
                  <Tag
                    key={index}
                    label={oneTag}
                    isSlected={false}
                    onClick={() => {
                      tagClicked(oneTag);
                    }}
                    deletable={true}
                  />
                ))}
            </div>
          )}

          <div
            className="flex justify-center flex-wrap gap-8"
            style={{ background: isAdmin ? primaryColor : "" }}
          >
            {!isAdmin && !isMobile && (
              <AddPhotoCard
                setToastStatus={setToastStatus}
                setToastMessage={setToastMessage}
              />
            )}
            {!isAdmin && isMobile && (
              <MobileAddPhotoCard
                setToastStatus={setToastStatus}
                setToastMessage={setToastMessage}
              />
            )}
            {/* {isAdmin && <span className=""></span>} */}
            {listOfPlaces &&
              listOfPlaces.map((place: any) => (
                <PhotoCard
                  id={place._id}
                  tags={place.tags}
                  photo={place.image}
                  key={place._id}
                  title={place.title}
                  position={place.location}
                  onClick={() => cardClicked(place._id)}
                  openModal={() => getCardDetailsAsAdmin(place._id)}
                  deleteCard={deleteCard}
                />
              ))}
          </div>
          {listOfPlaces.length > 0 && (
            <div className="flex w-full justify-center mt-10 mb-16 h-[40px]">
              <div className="flex items-center">
                <div className="w-[80px] flex justify-center">
                  {page > 1 ? (
                    <PrimaryButton
                      buttonText={"PREV"}
                      type={"button"}
                      onClick={() => setPage(page - 1)}
                      disabled={page === 1}
                    />
                  ) : (
                    <div className="w-[60px]"></div>
                  )}
                </div>

                <div className="flex w-[20px] justify-center items-center sm:text-white text-black">
                  {page}
                </div>

                <div className="w-[80px] flex justify-center">
                  {page < totalPages ? (
                    <PrimaryButton
                      buttonText={"NEXT"}
                      type={"button"}
                      onClick={() => setPage((prevPage) => prevPage + 1)}
                      disabled={isFetching}
                    />
                  ) : (
                    <div className="w-[80px]"></div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={() => SetisDeleteModalOpen(false)}
        title={"Sei sicuro di voler eliminare la foto?"}
        description={
          "Una volta eliminata la foto sarà cancellata definitivamente."
        }
        primaryButtonText={"TORNA ALLA HOME"}
        secondaryButtonText={"Elimina"}
        avoidingAction={() => navigate("/")}
        confirmingAction={() => deletePlaceMutation(idOfCard)}
        confirmingActionIcon={DeleteIcon}
      />

      {showModal && (
        <DownloadModal
          onClick={downloadPhoto}
          closemodal={closeModal}
          data={singlePlace}
          isDownloadingImage={isDownloadingImage}
        />
      )}
    </div>
  );
};
export default Home;
