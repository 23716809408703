import CloseIcon from "../../assets/svg/Close_LG.svg";
import Hide from "../../assets/svg/Hide.svg";
import Show from "../../assets/svg/Show.svg";
import PrimaryButton from "../primaryButton";
import Input from "../input";
import { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { loginAPI } from "../../api/api";
import { useDispatch } from "react-redux";
import {
  setFaceBook,
  setInstagram,
  setIsAdminValue,
  setName,
  setPicture,
  setRole,
  setTikTok,
} from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import BaseBackground from "../../pages/layout/BaseBackground";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialData = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail non valido")
      .required("L'e-mail è obbligatoria"),
    password: Yup.string()
      .min(6, "La password deve essere di almeno 6 caratteri")
      .required("Password è obbligatoria"),
  });

  const login = (data: any) => {
    setProcessing(true);
    loginAPI
      .login(data)
      .then((res) => {
        localStorage.setItem("token", res.data.token.toString());
        dispatch(setRole(res.data.role));
        dispatch(setIsAdminValue(res.data.role === "ADMIN" ? true : false));
        dispatch(setPicture(res.data.picture));
        dispatch(setName(res.data.name));
        dispatch(setInstagram(res.data.instagram));
        dispatch(setFaceBook(res.data.facebook));
        dispatch(setTikTok(res.data.tiktok));
        navigate("/");
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  return (
    <BaseBackground>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white flex px-10 py-8 items-center gap-[59px] flex-col rounded-18px sm:min-w-[539px] w-[300px] min-h-[391px] relative">
          <img
            src={CloseIcon}
            alt="Close"
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => {
              navigate("/welcome");
            }}
          />
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
              login(values);
            }}
            enableReinitialize={true}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="flex flex-col items-center justify-center sm:gap-[59px] gap-[20px] sm:px-0 px-4">
                    <div className="flex flex-col items-center">
                      <div className="flex px-1 py-0 items-center font-FiraSans sm:text-2xl text-xl font-normal">
                        Ciao!
                      </div>
                      <div className="flex sm:w-[402px] w-[250px] text-black sm:text-base text-xs font-normal text-center">
                        Accedi alla piattaforma mediante le credenziali tramite
                        cui ti sei precedentemente registrato.
                      </div>
                    </div>
                    <div className="sm:w-[459px] w-[280px]  flex flex-col gap-2">
                      <div className="flex sm:flex-row flex-col sm:gap-6 gap-2 w-full sm:items-center">
                        <div className="sm:text-base text-sm font-FiraSans text-black sm:w-4/12 w-full font-semibold">
                          Email:
                        </div>
                        <div className="sm:w-6/12 w-full">
                          <Input
                            placeholder={"Inserisci email"}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            // this on blur makes touched property get updated
                            onBlur={formik.handleBlur}
                            name="email"
                            required={true}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <p className="flex text-xs ml-3 py-1 text-RedPantone font-FiraSans font-semibold">
                              {formik.errors.email.toString()}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex sm:flex-row flex-col sm:gap-6 gap-2 w-full sm:items-center items-start">
                        <div className="sm:text-base text-sm font-FiraSans text-black w-4/12 font-semibold">
                          Password:
                        </div>
                        <div className=" flex flex-row sm:w-6/12 w-11/12 space-x-2">
                          <div className="w-full">
                            <Input
                              placeholder={"Inserisci password"}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              // this on blur makes touched property get updated
                              onBlur={formik.handleBlur}
                              name="password"
                              required={true}
                              type={showPassword ? "text" : "password"}
                            />
                            {formik.errors.password &&
                              formik.touched.password && (
                                <p className="flex ml-3 py-1 text-xs text-RedPantone font-FiraSans font-semibold">
                                  {formik.errors.password.toString()}
                                </p>
                              )}
                          </div>
                          <img
                            className="flex flex-1"
                            alt=""
                            src={showPassword ? Show : Hide}
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                      </div>
                    </div>
                    {processing ? (
                      <div className="flex items-center justify-center w-10 h-10">
                        <span className="button-loader w-full"></span>
                      </div>
                    ) : (
                      <PrimaryButton
                        buttonText={"Accedi"}
                        type={"submit"}
                        disabled={processing}
                      />
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </BaseBackground>
  );
};
export default Login;
