import axios, { AxiosResponse } from "axios";
import { LoginModel, LoginResponse } from "../models/LoginModel";
import { ParamsInterface } from "../models/paramsModel";
import store from "../redux/store";
import { setToInitialState } from "../redux/reducers/userReducer";
import { CheckEmailResponse, EmailParamsInterface } from "../models/checkEmail";
import { Image64Interface } from "../models/image64";

// const apiUrl = process.env.REACT_APP_BACKEND_URL;
// const apiUrl = "https://content-creator-api-trn6xfzp4q-oa.a.run.app/";
const apiUrl =
  "https://content-creator-platform-be-333040295705.europe-west1.run.app";

const headers = {
  "Content-Type": "application/json",
};

const loginInstance = axios.create({
  baseURL: apiUrl,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
});

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 300000,
  headers: headers,
});

interface loginInterface {
  login: (payload: LoginModel) => Promise<AxiosResponse<LoginResponse>>;
}
export const loginAPI: loginInterface = {
  login: (payload: LoginModel) =>
    loginInstance.post<string, any, LoginModel>(`user/login`, payload),
};

interface checkEmailInterface {
  checkEmail: (
    params: EmailParamsInterface
  ) => Promise<AxiosResponse<CheckEmailResponse>>;
}

export const checkEmailAPI: checkEmailInterface = {
  checkEmail: (params: EmailParamsInterface) =>
    loginInstance.get<string, any>(`user/check_user`, { params }),
};

interface getImage64Interface {
  getImage64: (params: Image64Interface) => Promise<AxiosResponse<any>>;
}

export const getImage64API: getImage64Interface = {
  getImage64: (params: Image64Interface) =>
    loginInstance.get<string, any>(`user/get_image`, { params }),
};

interface registerInterface {
  register: (payload: any) => Promise<AxiosResponse<any>>;
}
export const registerAPI: registerInterface = {
  register: (payload: any) =>
    axiosInstance.post<string, any>("user/register", payload),
};

interface getPlacesInterface {
  getPlaces: (params: ParamsInterface) => Promise<AxiosResponse<any>>;
}

export const getPlacesAPI: getPlacesInterface = {
  getPlaces: (params: ParamsInterface) =>
    axiosInstance.get<string, any>("place/", { params }),
};

interface createPlaceInterface {
  createPlace: (payload: any) => Promise<AxiosResponse<any>>;
}

export const createPlaceAPI: createPlaceInterface = {
  createPlace: (payload: any) =>
    axiosInstance.post<string, any>("place/", payload),
};

interface getSinglePlace {
  getSinglePlace: (id: string) => Promise<AxiosResponse<any>>;
}

export const getSinglePlaceAPI: getSinglePlace = {
  getSinglePlace: (id: string) => axiosInstance.get<string>(`place/${id}`),
};

interface deletePlaceInterface {
  deletePlace: (id: string) => Promise<AxiosResponse<any>>;
}
export const deletePlaceAPI: deletePlaceInterface = {
  deletePlace: (id: string) => axiosInstance.delete<string>(`place/${id}`),
};

interface getTagsInterface {
  getTags: () => Promise<AxiosResponse<any>>;
}

export const getTagsAPI: getTagsInterface = {
  getTags: () => {
    return axiosInstance.get<string, any>("tag/");
  },
};

interface editProfileInterface {
  editProfile: (payload: any) => Promise<AxiosResponse<any>>;
}

export const editProfileAPI: editProfileInterface = {
  editProfile: (payload: any) =>
    axiosInstance.put<string, any>("user/update", payload),
};

// this is an interceptor that makes sure that all requests have the auth token attached to them
axiosInstance.interceptors.request.use((config) => {
  let authToken: string | null = localStorage.getItem("token");

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  // console.log("request config", config);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(setToInitialState());
      // Redirect to the login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
