import { Route, Routes } from "react-router-dom";
import Home from "../../Home";
import CardDetails from "../../CardDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const AdminLayout = () => {
  const primaryColor = useSelector(
    (rootState: RootState) => rootState.userReducer.primaryColor
  );

  return (
    <div className="flex flex-row w-full" style={{ background: primaryColor }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/card-details/:id" element={<CardDetails />} />
      </Routes>
    </div>
  );
};
export default AdminLayout;
