import { useState } from "react";
import { ThemeColorInterface, ThemeColors } from "../../data/themeColors";
import BaseBackground from "../../pages/layout/BaseBackground";
import PrimaryButton from "../primaryButton";
import ThemeButtons from "../themeButtons";
import { useNavigate } from "react-router-dom";

const Theme = () => {
  const [selectedButton, setSelectedButton] = useState<number>(0);
  const navigate = useNavigate();
  return (
    <BaseBackground>
      <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-full h-full flex items-center justify-center z-50">
          <div className="bg-white flex px-10 py-8 items-center justify-center gap-6 flex-col rounded-18px sm:w-[600px] w-11/12 min-h-[70vh] max-h-[95vh] overflow-y-auto relative">
            {/* title section */}
            <div className="flex flex-col justify-between items-center w-full">
              {/* title section */}
              <div className="flex font-FiraSans sm:text-2xl text-base text-black">
                Scegli il colore della tua intefaccia!
              </div>
              {/* sub title */}
              <div className="flex font-FiraSans sm:text-base text-xs font-normal text-center ">
                Personalizza la tua interfaccia scegliendo, tra la nostre <br />
                palette, la coppia di colori che preferisci!
              </div>
            </div>
            {/* colors section */}
            <div className="flex flex-wrap w-full h-min justify-center items-center gap-2 mb-2">
              {ThemeColors.map((color: ThemeColorInterface) => (
                <ThemeButtons
                  id={color.id}
                  primaryColor={color.primaryColor}
                  secondaryColor={color.secondaryColor}
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                />
              ))}
            </div>
            {/* button */}
            <div className="flex w-full justify-center">
              <PrimaryButton
                buttonText={"SCELTO!"}
                type={"button"}
                onClick={() => {
                  navigate("/login");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseBackground>
  );
};
export default Theme;
