import { useRef, useState } from "react";
import ProfilePicture from "../../assets/svg/profile-empty.svg";
import CloseIcon from "../../assets/svg/Close_LG.svg";
import ProfileEmpty from "../../assets/svg/profile-empty.svg";
import FaceBook from "../../assets/svg/FaceBookIcon.svg";
import Instagram from "../../assets/svg/InstagramIcon.svg";
import TikTok from "../../assets/svg/TikTok.svg";
import ProfileCard from "../profileCard";
import Input from "../input";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PrimaryButton from "../primaryButton";
import { editProfileAPI } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setFaceBook,
  setInstagram,
  setName,
  setPicture,
  setTikTok,
} from "../../redux/reducers/userReducer";
import { RootState } from "../../redux/store";
import { ProfileResponse } from "../../models/ProfileModels";

interface SidebarProps {
  showToast: (color: string, message: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ showToast }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  // Retrieve values from redux
  const name = useSelector((state: RootState) => state.userReducer.name);
  const picture = useSelector((state: RootState) => state.userReducer.picture);
  const instagram = useSelector(
    (state: RootState) => state.userReducer.instagram
  );
  const tiktok = useSelector((state: RootState) => state.userReducer.tiktok);
  const facebook = useSelector(
    (state: RootState) => state.userReducer.facebook
  );

  const initialData = {
    name: name,
    picture: picture,
    instagram: instagram,
    tiktok: tiktok,
    facebook: facebook,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    picture: Yup.string(),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    facebook: Yup.string(),
  });
  const handleToastAction = (data: boolean) => {
    if (data) {
      showToast("success", "Profilo utente aggiornato con successo");
    } else {
      showToast("error", "Impossibile aggiornare il profilo utente");
    }
  };

  const modifyProfile = () => {
    setModalIsOpen(true);
  };
  const submitEditedProfile = (values: any) => {
    setIsLoading(true);
    editProfileAPI
      .editProfile(values)
      .then((res: ProfileResponse) => {
        // will get contents from response  from the response
        dispatch(setName(res.data.name));
        dispatch(setPicture(res.data.picture));
        dispatch(setTikTok(res.data.tiktok));
        dispatch(setInstagram(res.data.instagram));
        dispatch(setFaceBook(res.data.facebook));
        // close the edit modal
        setModalIsOpen(false);
        setIsLoading(false);
        // trigger success toast
        handleToastAction(true);
      })
      .catch((err) => {
        // trigger success toast
        handleToastAction(false);
        console.log(err);
      });
  };
  return (
    <>
      <div className="h-full flex flex-col items-center  w-303px shadow-sidebarShadow bg-white fixed z-10">
        <ProfileCard
          profilePic={picture ? picture : ProfilePicture}
          completeName={name}
          instagram={instagram}
          tiktok={tiktok}
          facebook={facebook}
          onClickModifyButton={modifyProfile}
        />
        {modalIsOpen && (
          <div className="fixed top-0 left-0 w-full h-full modal-container flex items-center justify-center bg-black bg-opacity-50">
            <div className="flex flex-col items-start bg-white w-[35%] rounded-18px">
              {/* modal top */}
              <div className="flex w-full pt-4 pr-4 pb-4 pl-10 justify-end items-start gap-10px  bg-white rounded-t-18px">
                <div className="flex justify-between items-center flex-1">
                  <p className="text-white text-2xl font-normal">
                    {/* Publica un nuovo contenuto */}
                  </p>
                  <img
                    src={CloseIcon}
                    alt="close icon"
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => {
                      setModalIsOpen(false);
                    }}
                  />
                </div>
              </div>
              {/* body */}
              <div className="flex bg-white p-5 h-min rounded-b-18px items-center justify-center w-full">
                <Formik
                  initialValues={initialData}
                  validationSchema={validationSchema}
                  onSubmit={(values: any) => {
                    submitEditedProfile(values);
                  }}
                  enableReinitialize={true}
                >
                  {(formik) => {
                    const uploadPicture = () => {
                      fileInputRef.current?.click();
                    };
                    return (
                      <Form className="flex w-full h-full items-center justify-center">
                        <div className="flex flex-col w-full h-full items-center justify-center gap-6">
                          {/* profile pic */}
                          <div className="rounded-container">
                            <img
                              className="w-full h-full object-cover"
                              src={formik.values.picture || ProfileEmpty}
                              alt=""
                            />
                          </div>
                          <div>
                            <p
                              className="flex h-5 items-center  gap-2 font-FiraSans text-base font-normal text-black underline"
                              onClick={uploadPicture}
                            >
                              <label>Cambia foto</label>
                            </p>
                            <input
                              id="imageUpload"
                              ref={fileInputRef}
                              type="file"
                              accept="image/jpeg, image/jpg, image/png, image/webp, image/heic, image/heif"
                              style={{ display: "none" }}
                              onChange={(event: any) => {
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  const file = event.target.files[0];
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    formik.setFieldValue(
                                      "picture",
                                      reader.result
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                            />
                          </div>

                          {/* rest of the form */}
                          <div className="flex flex-col justify-center items-center w-full space-y-2">
                            <div className="flex flex-row gap-6 w-full items-center mb-6 ">
                              <div className="text-base font-FiraSans text-black w-4/12 font-semibold ">
                                Nome Cognome:
                              </div>
                              <div className="w-5/6">
                                <Input
                                  placeholder={"Inserisci nome e cognome"}
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  // this on blur makes touched property get updated
                                  onBlur={formik.handleBlur}
                                  name="name"
                                  required={true}
                                />
                                {formik.errors.name && formik.touched.name && (
                                  <p className="flex text-RedPantone font-FiraSans font-semibold">
                                    {formik.errors.name.toString()}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-row w-full">
                              <div className="text-base font-FiraSans text-black w-4/12 font-semibold justify-start">
                                Profili social:
                              </div>
                              <div className="flex flex-col w-[90%] gap-2">
                                <div className="flex flex-row space-x-2 w-full items-center justify-center">
                                  <img
                                    alt=""
                                    className="flex w-18px h-18px"
                                    src={Instagram}
                                    onClick={() => {}}
                                  />

                                  <Input
                                    placeholder={"Inserisci testo"}
                                    value={formik.values.instagram}
                                    onChange={formik.handleChange}
                                    name="instagram"
                                    type={"text"}
                                    className=""
                                  />
                                </div>
                                <div className="flex flex-row space-x-2 w-full items-center justify-center">
                                  <img
                                    alt=""
                                    className="flex w-18px h-18px"
                                    src={TikTok}
                                    onClick={() => {}}
                                  />
                                  <Input
                                    placeholder={"Inserisci testo"}
                                    value={formik.values.tiktok}
                                    onChange={formik.handleChange}
                                    name="tiktok"
                                    type={"text"}
                                    className=""
                                  />
                                </div>
                                <div className="flex flex-row space-x-2 w-full items-center justify-center">
                                  <img
                                    alt=""
                                    className="flex w-18px h-18px"
                                    src={FaceBook}
                                    onClick={() => {}}
                                  />
                                  <Input
                                    placeholder={"Inserisci testo"}
                                    value={formik.values.facebook}
                                    onChange={formik.handleChange}
                                    name="facebook"
                                    type={"text"}
                                    className=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {isLoading ? (
                            <div className="flex items-center justify-center w-10 h-10">
                              <span className="button-loader w-full"></span>
                            </div>
                          ) : (
                            <PrimaryButton
                              buttonText={"Salva"}
                              type={"submit"}
                              disabled={!formik.isValid || isLoading}
                            />
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Sidebar;
