import React from "react";
import { useDispatch } from "react-redux";
import {
  setPrimaryColor,
  setSecondaryColor,
} from "../../redux/reducers/userReducer";

interface ThemeButtonsProps {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  selectedButton: number;
  setSelectedButton: (id: number) => void;
}

const ThemeButtons: React.FC<ThemeButtonsProps> = ({
  id,
  primaryColor,
  secondaryColor,
  selectedButton,
  setSelectedButton,
}) => {
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    setSelectedButton(id);
    //set primary and secondary buttons to redux
    dispatch(setPrimaryColor(primaryColor));
    dispatch(setSecondaryColor(secondaryColor));
  };
  return (
    <div
      className="inline-flex sm:p-4 p-2 items-start sm:gap-5 gap-2 rounded-40px border border-black-50"
      onClick={handleButtonClick}
      style={{
        border: `2px solid ${
          selectedButton === id ? secondaryColor : "#D9D9D9"
        }`,
      }}
    >
      <div
        className="flex items-start sm:gap-[10px] gap-[5px] rounded-full sm:w-[50px] w-[25px] sm:h-[50px] h-[25px]"
        style={{ background: primaryColor }}
      ></div>
      <div
        className="flex items-start sm:gap-[10px] gap-[5px] rounded-full sm:w-[50px] w-[25px] sm:h-[50px] h-[25px]"
        style={{ background: secondaryColor }}
      ></div>
    </div>
  );
};

export default ThemeButtons;
